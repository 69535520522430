import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HttpService } from '../services/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private http: HttpService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('X-API-KEY') || req.params.has('no_intercept') || req.url.endsWith('.json')) return next.handle(req);

        return this.http.getPublicKey().pipe(
            switchMap(token => {
                const request = req.clone({
                    headers: req.headers.set('X-API-KEY', token)
                });

                return next.handle(request);
            })
        );
    }
}
