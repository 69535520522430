import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { switchMap, shareReplay, retryWhen, mergeMap, retry } from 'rxjs/operators';

import { HttpConfig } from '../models/http-config.model';
import { MemoryStorage } from './memory-storage.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    public baseURL: string;
    public publicKey: Observable<string>;
    public mode: 'live'|'test';
    public params = { origin_app: '', origin_cta: '' };
    public extraParams = { origin_app: '', origin_cta: '' };
    public appKey: string;
    public apiKey: string;
    public lang = 'en';

    constructor(
        private http: HttpClient,
        private memoryStorage: MemoryStorage
    ) { }

    public get isTestMode(): boolean {
        return this.mode === 'test';
    }

    public getPublicKey(): Observable<string> {
        if (this.publicKey) return this.publicKey;

        const key = this.getKeyFromMemoryStorage();
        if (key) return of(key);


        this.publicKey = this.http.get<any>(`${this.baseURL}/auths?access=public`, {
            observe: 'response',
            headers: {
                'X-APP-KEY': this.appKey,
                'X-API-KEY': this.apiKey
            }
        }).pipe(
            switchMap(data => {
                if(data.body && data.body.auths && data.body.auths.jwt){
                    this.memoryStorage.setItem('publicKey', data.body.auths.jwt);
                    return of(data.body.auths.jwt);
                }else{
                    this.memoryStorage.setItem('publicKey','');
                    return of('');
                }
            }),
            shareReplay(1)
        );

        return this.publicKey;
    }

    public get(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.baseURL}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2,delay:(err, i) => {
                if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public getFile(url: string, config?: HttpConfig) {
        return this.http.get(`${this.baseURL}${url}`, {
            responseType: 'blob', observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2,delay:(err, i) => {
                if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public post(url: string, body: any, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2,delay:(err, i) => {
                if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public patch(url: string, body: object, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.patch<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2,delay:(err, i) => {
                if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public put(url: string, body: object, config: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.put<any>(`${this.baseURL}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2,delay:(err, i) => {
                if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(()=>err);

                this.memoryStorage.removeItem('publicKey');
                this.publicKey = undefined;

                return timer(2000 * i);
            }})
        );
    }

    public delete(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.baseURL}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2,delay:(err, i) => {
                    if (i > 2 || (err.status !== 401 && err.status !== 403)) return throwError(()=>err);

                    this.memoryStorage.removeItem('publicKey');
                    this.publicKey = undefined;

                    return timer(2000 * i);
                }})
        );
    }

    private parseConfig(config?: HttpConfig): HttpConfig {
        const newConfig: HttpConfig = {
            headers: { 'X-APP-KEY': this.appKey }
        };

        newConfig.params = this.filterParams({ lang: this.lang, lang_single: '1', ...(config ? config.params : {}), ...this.params });

        if (config && config.headers) newConfig.headers = { ...newConfig.headers, ...this.filterParams(config.headers, true) };

        return newConfig;
    }

    private filterParams(params: { [key: string]: any }, headers = false): any {
        const data: any = { ...(headers ? {} : this.extraParams) };

        Object.keys(params).forEach(key => {
            if (!params.hasOwnProperty(key) || !params[key]) return;

            if (params[key] === true || params[key] === false) data[key] = params[key] ? '1' : '0';
            else data[key] = params[key];
        });

        return data;
    }

    private getKeyFromMemoryStorage(): string {
        const key = this.memoryStorage.getItem('publicKey');

        try {
            JSON.parse(key);

            return null;
        } catch (err) {
            return key;
        }
    }
}
