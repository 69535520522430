<div class="gn-p-5">
    <form class="gn-w-full" [formGroup]="form" (ngSubmit)="makeTransaction()" autocomplete="off">
        <input formControlName="customer_name" [placeholder]="'customer_data.name' | translate" class="input gn-w-full gn-mb-2" type="text" required/>
        <input formControlName="customer_email" [placeholder]="'customer_data.email' | translate" class="input gn-w-full gn-mb-2" type="email" email required/>
        <input formControlName="customer_tel" [placeholder]="'customer_data.phone' | translate" class="input gn-w-full gn-mb-2" type="text" required/>
        <label class="gn-block gn-text-sm gn-text-gray-700">
            <input class="w-8" type="checkbox" formControlName="subscribed" /> Subscribe to our newsletter
        </label>
        <label class="gn-block gn-text-sm gn-text-gray-700">
            <input class="w-8" type="checkbox" formControlName="terms" required/> Tick this box to show that you have read and agreed to our <a href="javascript:void(0)" (click)="termsEl.hidden = !termsEl.hidden" class="gn-underline">Terms and Conditions</a>
        </label>
        <div class="gn-p-3 gn-border gn-mt-4" hidden #termsEl>
            <div *ngFor="let item of terms; last as isLast" [class.gn-mb-3]="!isLast">
                <h5 [innerHtml]="item.title" class="gn-m-0 gn-mb-1 gn-font-semibold gn-text-xs gn-text-gray-700"></h5>
                <p [innerHtml]="item.text" class="gn-m-0 gn-text-xs gn-text-gray-700"></p>
            </div>
        </div>

        <div class="gn-mt-4" *ngIf="captchaEnabled === true && captchaError===false && !form.get('customer_name').invalid && !form.get('customer_email').invalid && !form.get('customer_tel').invalid">
        <form [formGroup]="aFormGroup" >
            <ngx-recaptcha2 #captchaElem
              [siteKey]="reCaptchaSiteKey"
              (reset)="handleCaptchaReset()"
              (expire)="handleCaptchaExpire()"
              (error)="handleCaptchaError($event)"
              (load)="handleCaptchaLoad()"
              (success)="handleCaptchaSuccess($event)"
              [useGlobalDomain]="false"
              formControlName="recaptcha">
            </ngx-recaptcha2>
          </form>
        </div>
        <div class="gn-w-full gn-mt-2 gn-mb-4">
            <div class="gn-flex gn-w-full">
                <div class="gn-w-1/2 gn-pr-1">
                    <input [placeholder]="'payments.promocode' | translate" formControlName="code" class="input gn-w-full" type="text" />
                </div>
                <button type="button" (click)="applyPromoVoucher()" class="gn-text-white gn-bg-main gn-ml-1 gn-px-4 gn-rounded">
                    <span *ngIf="!codeLoading">{{ 'payments.redeem' | translate }}</span>
                    <div class="gn-spinner-loader" *ngIf="codeLoading">
                        <div class="gn-bounce1"></div>
                        <div class="gn-bounce2"></div>
                        <div class="gn-bounce3"></div>
                    </div>
                </button>
            </div>
            <div *ngIf="appliedPromo" class="gn-w-1/2 gn-flex gn-items-center gn-justify-between gn-mt-2 gn-py-1 gn-px-2 gn-bg-gray-200 gn-rounded-full">
                <p class="gn-m-0 gn-text-gray-600 gn-text-sm gn-uppercase gn-font-medium">{{ 'payments.promocode' | translate }}: {{ appliedPromo.promo.promocode }}</p>
                <button (click)="removePromo()" type="button" class="gn-w-4 gn-h-4 gn-rounded-full gn-bg-gray-400 gn-text-gray-500 hover:gn-text-white gn-text-xs gn-flex gn-items-center gn-justify-center">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>

            <div class="gn-w-1/2 gn-pt-4">
                <ng-select
                    [items]="saleTypes"
                    bindLabel="text"
                    bindValue="id"
                    placeholder="Reason"
                    appearance="outline"
                    formControlName="sale_type"
                    (change)="changeSaleType()"
                ></ng-select>
            </div>   

        </div>
        <div [class.gn-hidden]="dueNow <= 0">
            <div class="gn-flex md:gn-flex-row gn-flex-col gn-overflow-hidden md:gn-h-25px gn-relative">
                <div class="md:gn-flex-1 md:gn-w-auto gn-w-full gn-flex gn-items-start gn-justify-center gn-cursor-pointer gn-mb-2 md:gn-mb-0 gn-h-25px">
                    <div class="md:gn-flex-1 md:gn-w-auto gn-w-full gn-rounded gn-flex gn-justify-center gn-cursor-pointer gn-mb-2 md:gn-mb-0 gn-h-35px gn-p-1.5" style="background: #eeeeee;" (click)="cardHidden = !cardHidden; scrollTo(cardTarget)" [class.gn-hidden]="!profiles.includes('stripe')">
                        <div class="gn-h-8 gn-w-8 gn-mr-2 gn-bg-contain gn-bg-no-repeat" style="background-image: url('https://images.rezbot.com/widgets/assets/visa.svg')"></div>
                        <div class="gn-h-8 gn-w-8 gn-mr-2 gn-bg-contain gn-bg-no-repeat" style="background-image: url('https://images.rezbot.com/widgets/assets/master-card.svg')"></div>
                        <div class="gn-h-8 gn-w-8 gn-mr-2 gn-bg-contain gn-bg-no-repeat" style="background-image: url('https://images.rezbot.com/widgets/assets/american-express.svg')"></div>
                        <span class="gn-text-gray-700 gn-font-medium gn-text-base">{{ 'payments.card_payment' | translate }}</span>
                    </div>
                </div>
                <!--div
                    *ngIf="profiles.includes('paypal')"
                    class="md:gn-flex-1 md:gn-w-auto gn-w-full gn-h-45px  gn-rounded gn-flex gn-items-center gn-justify-center gn-mb-2 md:gn-mb-0 md:gn-ml-2"
                    style="background: #ffc439"
                    [class.gn-hidden]="!profiles.includes('paypal')"
                    [ngClass]="form.get('customer_name').invalid || form.get('customer_email').invalid || form.get('customer_tel').invalid || form.get('customer_tel').invalid || !form.get('terms').value ? 'gn-pointer-events-none gn-opacity-50' : ''"
                >
                    <div class="gn-w-full" id="rezbot-paypal-el"></div>
                </div-->
                <div
                class="md:gn-flex-1 md:gn-w-auto gn-w-full gn-h-45px  gn-rounded gn-flex gn-items-center gn-justify-center gn-mb-2 md:gn-mb-0 md:gn-ml-2"
                [class.gn-hidden]="!profiles.includes('paypal') || !showPaypal"
                
                [ngClass]="form.get('customer_name').invalid || form.get('customer_email').invalid || form.get('customer_tel').invalid || form.get('customer_tel').invalid || !form.get('terms').value ? 'gn-pointer-events-none gn-opacity-50' : ''"
                >
                    <div class="gn-w-full">
                        <div class="gn-w-full" id="rezbot-voucher-paypal-el"></div>
                        <div #paypalPP class="gn-my-2 md:gn-mx-2"></div>
                    </div>
                    
    
                    <!--div
                                              class="md:gn-flex-1 md:gn-w-auto gn-w-full gn-h-full gn-rounded gn-flex gn-items-center gn-justify-center gn-mb-2 md:gn-mb-0 md:gn-ml-2"
                                              style="background: #ffc439"
                                              [class.gn-hidden]="!profiles.includes('worldpay')"
                                              [ngClass]="form.get('customer_name').invalid || form.get('customer_email').invalid || form.get('customer_tel').invalid ? 'gn-pointer-events-none gn-opacity-50' : ''"
                                          >
                                              <div class="gn-w-full" #worldpayEl></div>
                    </div-->
    
                    <div class="gn-absolute gn-inset-0" style="z-index: 1000;" *ngIf="loading"></div>
                </div>

                <div
                class="md:gn-flex-1 md:gn-w-auto gn-w-full gn-h-full gn-rounded gn-bg-white gn-flex gn-items-center gn-justify-center md:gn-ml-2"
                [class.gn-hidden]="!showPaymentRequest"
                [ngClass]="form.get('customer_name').invalid || form.get('customer_email').invalid || form.get('customer_tel').invalid || !form.get('terms').value ? 'gn-pointer-events-none gn-opacity-50' : ''"
                >
                    <div class="gn-w-full gn-h-full" #nativeEl></div>
                </div>

            </div>
            
            <div  #cardTarget>
                <div [class.gn-hidden]="cardHidden" class="gn-mt-2 gn-flex md:gn-flex-row gn-flex-col gn-items-center">
                    <div class="input md:gn-mr-2 md:gn-mb-0 md:w-auto md:gn-flex-1 gn-w-full gn-mb-2">
                        <div class="gn-w-full" #cardEl></div>
                    </div>
                    <input formControlName="card_zip" class="input md:gn-w-40 gn-w-full" type="text" placeholder="Post Code" />
                </div>
            </div>
        </div>
        <div class="gn-w-full gn-text-right gn-mt-4" *ngIf="!cardHidden || this.dueNow <= 0">
            <button type="submit" class="gn-px-4 gn-py-2 gn-bg-blue-500 gn-rounded gn-uppercase gn-font-semibold gn-text-white" [disabled]="form.invalid || loading || this.captchaEnabled && !this.captchaError && !this.captchaSuccess">
                <span class="gn-text" *ngIf="!loading">{{ hint || 'buttons.pay_now' | translate }}</span>
                <span class="gn-hint" *ngIf="!loading">{{ hint || 'buttons.pay_now' | translate }}</span>
                <div class="gn-spinner-loader" *ngIf="loading">
                    <div class="gn-bounce1"></div>
                    <div class="gn-bounce2"></div>
                    <div class="gn-bounce3"></div>
                </div>
            </button>
        </div>
        <div class="gn-w-full gn-text-right gn-mt-4" *ngIf="cardHidden && this.dueNow > 0">
            <button type="submit" class="gn-px-4 gn-py-2 gn-bg-blue-500 gn-rounded gn-uppercase gn-font-semibold gn-text-white" [disabled]="form.invalid || loading">
                <span class="gn-text" *ngIf="!loading">{{ hint || 'buttons.pay_now' | translate }}</span>
                <span class="gn-hint" *ngIf="!loading && hint != 'buttons.pay_now'">{{ hint || 'buttons.pay_now' | translate }}</span>
                <div class="gn-spinner-loader" *ngIf="loading">
                    <div class="gn-bounce1"></div>
                    <div class="gn-bounce2"></div>
                    <div class="gn-bounce3"></div>
                </div>
            </button>
        </div>
    </form>
</div>
<div class="gn-p-5 gn-bg-gray-200">
    <p class="gn-text-gray-700 gn-font-medium gn-text-xl gn-text-right" *ngIf="dueNow != total">Voucher(s) Cost: {{ total | currency:company.currency }}</p>
    <p class="gn-text-gray-700 gn-font-medium gn-text-xl gn-text-right" *ngIf="dueNow < total">Discount : {{ (total-dueNow) | currency:company.currency }}</p>
    <p class="gn-text-gray-700 gn-font-medium gn-text-xl gn-text-right">{{ 'totals.total' | translate }}: {{ dueNow | currency:company.currency }}</p>
</div>
