<div class="gn-voucher-imports" [attr.data-cart]="(sale?.vouchers??[]).length">
    <div class="gn-fixed gn-top-0 gn-left-0 gn-w-full gn-bg-overlay gn-flex gn-items-center gn-justify-center gn-h-full" style="z-index: 999; ">
        <div class="gn-bg-white gn-rounded gn-overflow-hidden gn-shadow-lg gn-p-5" *ngIf="lastVoucher">
            <p class="gn-text-gray-700 gn-font-bold">{{ qty }} {{ lastVoucher.preset ? lastVoucher.preset.name : (lastVoucher.value | currency:company.currency )}} {{ qty > 1 ? 'vouchers have' : 'voucher has' }} been added to your cart</p>
            <div class="gn-flex gn-items-center gn-justify-end gn-mt-4">
                <button type="button" (click)="close()" class="gn-text-white gn-bg-blue-500 gn-px-5 gn-py-2 gn-rounded gn-text-sm gn-mr-2">
                    Continue Browsing
                </button>
                <button type="button" (click)="lastVoucher = null" class="gn-text-white gn-bg-main gn-px-5 gn-py-2 gn-rounded gn-text-sm">
                    View Cart
                </button>
            </div>
        </div>
        <div class="gn-w-full gn-bg-white gn-rounded gn-overflow-hidden gn-shadow-lg gn-flex gn-flex-col" style="max-width: 900px; max-height: 88vh; height:600px;" *ngIf="!lastVoucher"
        [ngClass]="mode==='test' ? 'gn-border-red-500 gn-border-2':''">
            <p *ngIf="mode === 'test'"
                    class="gn-w-full gn-bg-red-100 gn-text-center gn-text-red-500 gn-font-bold"
                    [tooltip]="mode==='test'? 'Card Payments will be faked.<br/>NO MONEY WILL BE COLLECTED.<br/>This mode should not use for customer bookings':''">!! TEST MODE !!</p>
            <div class="gn-w-full gn-flex gn-bg-gray-500 gn-p-2">
                <div class="gn-flex-1">
                    <button type="button" class="gn-uppercase gn-text-white gn-bg-blue-500 gn-text-xs gn-py-1 gn-px-2 gn-rounded" (click)="close()"  *ngIf="!company?.voucher_config?.use_presets">
                        <fa-icon *ngIf="!sale" [icon]="faChevronLeft"></fa-icon> {{ ((+sale?.total_paid > 0 || +sale?.total_pending > 0) ? 'buttons.close' : 'buttons.back') | translate }}
                    </button>

                    <button type="button" class="gn-uppercase gn-text-white gn-bg-blue-500 gn-text-xs gn-py-1 gn-mx-4 gn-px-2 gn-rounded" (click)="close()" *ngIf="company?.voucher_config?.use_presets">
                        <fa-icon *ngIf="!sale" [icon]="faChevronLeft"></fa-icon> Buy Another
                    </button>

                </div>
                <div class="gn-flex-auto gn-items-center gn-justify-center">
                    <p class="gn-text-gray-700 gn-font-medium gn-text-lg">{{ header | translate }}</p>
                </div>
                <button type="button" class="gn-uppercase gn-text-white gn-bg-red-500 gn-text-xs gn-py-1 gn-px-2 gn-rounded" (click)="close()" *ngIf="company?.voucher_config?.use_presets">
                    Close
               </button>
            </div>
            <div class="gn-flex-1 gn-overflow-scroll gn-w-full">
                <div *ngIf="!payment && !company?.currency">Loading...</div>
                <app-basket
                    *ngIf="!show_confirmation && !payment && company?.currency && !(+sale?.total_paid > 0 || +sale?.total_pending > 0)"
                    [form]="form"
                    [totalVouchers]="totalVouchers"
                    [totalWrapping]="totalWrapping"
                    [totalDelivery]="totalDelivery"
                    [total]="total"
                    [company]="company"
                    [brandId]="brandId"
                    (deleted)="deleteVoucher($event)"
                    (added)="addVoucher($event)"
                    (pay)="toPayment()"
                ></app-basket>
                <app-payment
                    *ngIf="!show_confirmation && payment && (!(+sale?.total_paid > 0 || +sale?.total_pending > 0) || sale_preloaded)"
                    [vouchers]="form"
                    [total]="total"
                    [company]="company"
                    [brandId]="brandId"
                    [sale]="sale"
                    (payment)="setSale($event)"
                ></app-payment>

                <ng-container *ngIf="show_confirmation">
                    <div class="gn-w-full gn-text-gray-700 gn-p-5">
                        <p class="gn-text-center gn-text-xl gn-mb-2 gn-font-bold">{{ 'voucher_success.ref' | translate }}: {{ sale?.sale_id }}</p>
                        <p class="gn-text-center">{{ 'voucher_success.a_copy' | translate }} {{ sale.customer_email }}</p>
                        <p class="gn-text-center">{{ 'voucher_success.few_min' | translate }}</p>
                        <p class="gn-text-center" *ngIf="directories.mail_services.length > 1">{{ 'voucher_success.postal' | translate }}</p><br />
                        <p (click)="downloadVoucher()" class="gn-text-center gn-cursor-pointer" *ngIf="company.voucher_config?.autoconfirm_vouchers || +company.company_id === 2">
                            Alternatively you can <b>DOWNLOAD</b> a copy of the voucher by clicking here.
                        </p>
                        <p *ngIf="sale.tracker" [innerHtml]="sale.tracker | safeHtml"></p>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>